import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Order, PaginationProps } from 'Types/global.types.ts'
import { UserTypes } from 'Types/user.types.ts'
import { AdminPaymentType, CustomersSortType, CustomFileType } from 'Types/admin/admin'

import {
  getCustomerCustomFiles,
  getCustomerList,
  getPaymentHistoryList,
  removeCustomer,
  searchCustomers,
  toggleBlockingCustomer,
  updateCustomer,
  updateCustomerPaymentType,
  updateCustomerStatus,
  uploadCustomerCustomFile,
  uploadCustomerDocument,
} from 'Api/admin/admin.customers.service.ts'

interface InitialStateProps {
  customerList: UserTypes[]
  initialCustomerList: UserTypes[]
  loading: boolean
  total: number
  pageIndex: number
  pageSize: number
  customerCustomFiles: CustomFileType[]
  customFilesTotal: number
  sort: CustomersSortType
  onboardingStatus: 'ALL' | 'FINISHED' | 'UNFINISHED'
  order: 'ASC' | 'DESC'
  param: string
  paymentHistory: AdminPaymentType[]
  paymentHistoryTotal: number
}

const initialState: InitialStateProps = {
  customerList: [],
  initialCustomerList: [],
  loading: false,
  total: 0,
  pageIndex: 0,
  pageSize: 20,
  customerCustomFiles: [],
  customFilesTotal: 0,
  sort: 'CREATED_AT',
  onboardingStatus: 'ALL',
  order: 'ASC',
  param: '',
  paymentHistory: [],
  paymentHistoryTotal: 0,
}

const customerListSlice = createSlice({
  name: 'customerList',
  initialState,
  reducers: {
    setCustomerListPageIndex: (state, action: PayloadAction<PaginationProps>) => {
      state.pageIndex = action.payload.pageIndex
    },
    setParam: (state, action: PayloadAction<string>) => {
      state.param = action.payload
      state.pageIndex = 0
    },
    setOrder: (state, action: PayloadAction<Order>) => {
      if (action.payload) {
        state.order = action.payload
        state.pageIndex = 0
      }
    },
    setSort: (state, action: PayloadAction<CustomersSortType>) => {
      if (action.payload) {
        state.sort = action.payload
        state.pageIndex = 0
      }
    },
    setOnboardingStatus: (state, action) => {
      if (action.payload) {
        state.onboardingStatus = action.payload
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomerList.fulfilled, (state, action) => {
        if (action.payload) {
          state.customerList = action.payload.customerList
          state.initialCustomerList = action.payload.customerList
          state.total = action.payload.total
        }
        state.loading = false
      })
      .addCase(getCustomerList.pending, (state) => {
        state.loading = true
      })
      .addCase(getCustomerList.rejected, (state) => {
        state.loading = false
      })
      .addCase(uploadCustomerDocument.fulfilled, (state, action) => {
        if (action.payload) {
          state.customerList = state.customerList.map((customer) => {
            if (customer.id === action.payload.customer.id) {
              return action.payload.customer
            } else {
              return customer
            }
          })
        }
      })
      .addCase(toggleBlockingCustomer.fulfilled, (state, action) => {
        if (action.payload) {
          state.customerList = state.customerList.map((customer) => {
            if (customer.id === action.payload.customer.id) {
              return action.payload.customer
            }
            return customer
          })
        }
      })
      .addCase(searchCustomers.fulfilled, (state, action) => {
        if (action.payload) {
          state.customerList = action.payload.customerList
          state.initialCustomerList = action.payload.customerList
          state.total = action.payload.total
        }
        state.loading = false
      })
      .addCase(searchCustomers.pending, (state) => {
        state.loading = true
      })
      .addCase(searchCustomers.rejected, (state) => {
        state.loading = false
      })
      .addCase(removeCustomer.fulfilled, (state, action) => {
        if (action.payload.deleted) {
          state.customerList = state.customerList.filter((customer) => customer.id !== action.payload.customerId)
        }
      })
      .addCase(getCustomerCustomFiles.fulfilled, (state, action) => {
        if (action.payload) {
          state.customerCustomFiles = action.payload.customerList
          state.customFilesTotal = action.payload.total
        }
      })
      .addCase(uploadCustomerCustomFile.fulfilled, (state, action) => {
        if (action.payload) {
          state.customerCustomFiles.unshift(action.payload.customFile)
          state.customFilesTotal += 1
        }
      })
      .addCase(updateCustomerPaymentType.fulfilled, (state, action) => {
        if (action.payload) {
          state.customerList = state.customerList.map((customer) => {
            if (customer.id === action.payload.customer.id) {
              return action.payload.customer
            }
            return customer
          })
        }
      })
      .addCase(updateCustomer.fulfilled, (state, action) => {
        state.customerList = state.customerList.map((customer) => {
          if (customer.id === action.payload.customer.id) {
            return action.payload.customer
          }
          return customer
        })
      })
      .addCase(getPaymentHistoryList.fulfilled, (state, action) => {
        if (action.payload) {
          state.paymentHistory = action.payload.paymentHistory
          state.paymentHistoryTotal = action.payload.total
        }
      })
      .addCase(updateCustomerStatus.fulfilled, (state, action) => {
        if (action.payload) {
          state.customerList = state.customerList.map((customer) => {
            if (customer.id === action.payload.customer.id) {
              return action.payload.customer
            }
            return customer
          })
        }
      })
  },
})

const { actions } = customerListSlice
export const { setCustomerListPageIndex, setSort, setOrder, setParam, setOnboardingStatus } = actions
export default customerListSlice.reducer
